import "../../core/src/components/workspace-selector/workspace-card/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/workspace-selector/workspace-card/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VW227bMAx9z1cIAQY0QBXIadJmLvohw9AHWqYdrrJkSHIuHfrvg2UnsWMnW4HuKQ4lUjyH5JHmMqoeVCnY7wljG6R842O2EuX+ecJYSq5UcIhZpjAYQFGuOXksXMwkao+2NudQxmzd+JSQpqTzmAn22FgSY1O03EJKlYvZsrGayivSGDNtNNaGHaV+E7NIiG/13wL2vDWtRJuPNMrYmG3B3nEOWUYauce956WlAuyBhw2z58nHZN7iijdmizagk5V1tXtp6Jh4AvItt6bS6UXU4DUMF4VAbQCOW9TenRGYEiT5Q8zEfN11GskhxQwq5S9zaCKdPBe9qiwXLXNbtJkyu5htKE1RdxwegsOgbPUvT8mi9GR0XPNYFXpQ0LDNebAhL29Bu8zYIm4+FXj8cSdmpzVqgp32MTFfOSarhCRP8J3Q3s2Xq/v5IroX99GMCdeF9jMFDxykpy2+TL2tcPrKejDGE+A1Dd2aLMPmi+4JUHJbkxSNczbk9RO0/aqcp6zuN+1R++4sdKlpG+LriFk2i/5Q4stUG1uAmr4G/Ofeux4rA+WuBWt6+VawVVjKjPbc0TteDExjL0J3qDBBLbuLlt2wYXcSGFHbKoeWO1QofWeKRkoVhvy8gEpR6cgF2diQR+5KkEFLdhbKETU51ZZ0yC5RRr51wD2OD04QthbAFe27ScjejRAiRghZNoSMCVyiQL7xlZj10nXbvNf3rdYeDzn+HwtI0mjuUBqdgj10wz79HxZutcVVST8lOFThpy9F3wF82RXrv7f8rQp3rsL2cixIX+w6nfX9s9J9Q4Nqp6vCdzoRzirb6hUoxcQ8enAMwXUvZdFl9whvbFALsDlprjDzrde/viFuwCkVSBx6jL8rErPnbgNpnddRUrpXbMBbgkUdbrnBo2DwdOix1fJxpKAHdz1MoNcvjZFHs5svj0bP1r1xT8LZW3KUkArCfGZ8VKsvch/6h2/Vf7VEz5OPP04lNggPCgAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var container = 'c1u3lp0';
export var disable = 'c1u3lp1';
export var settingButton = 'c1u3lpa';
export var showOnCardHover = 'c1u3lpb';
export var workspaceActiveStatus = 'c1u3lp7';
export var workspaceInfo = 'c1u3lp4';
export var workspaceInfoSlide = 'c1u3lp3';
export var workspaceInfoSlider = 'c1u3lp2';
export var workspaceInfoTooltip = 'c1u3lp8';
export var workspaceName = 'c1u3lp5';
export var workspaceStatus = 'c1u3lp6';
export var workspaceTitleContainer = 'c1u3lp9';